import React from 'react'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  display: flex;
  align-items: 'center';
  margin-top: ${({ theme }) => theme.spacings.xLarge};
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.spacings.xxLarge};
  margin-left: auto;
`

const LoadingSVG = styled.svg`
  transform: scale(2);
  width: 24px;
  height: 30px;
`

const Rectangle = styled.rect`
  fill: 'blue';
`

const Loading = () => {
  return (
    <>
      <LoadingContainer>
        <LoadingSVG x="0px" y="0px" viewBox="0 0 24 30">
          <Rectangle x="0" y="13" width="4" height="5" rx="2" fill="white">
            <animate
              attributeName="height"
              attributeType="XML"
              values="5;21;5"
              begin="0s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              attributeType="XML"
              values="13; 5; 13"
              begin="0s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </Rectangle>
          <rect x="10" y="13" width="4" height="5" rx="2" fill="white">
            <animate
              attributeName="height"
              attributeType="XML"
              values="5;21;5"
              begin="0.15s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              attributeType="XML"
              values="13; 5; 13"
              begin="0.15s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="20" y="13" width="4" height="5" rx="2" fill="white">
            <animate
              attributeName="height"
              attributeType="XML"
              values="5;21;5"
              begin="0.3s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              attributeType="XML"
              values="13; 5; 13"
              begin="0.3s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
        </LoadingSVG>
      </LoadingContainer>
    </>
  )
}

export default Loading
