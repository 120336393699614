import React from 'react'
import styled from 'styled-components'

// Master Header
import { H1 } from '../../../theme/Typography'

const PageHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacings.xLarge};
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: ${({ theme }) => theme.spacings.small};
    margin-bottom: ${({ theme }) => theme.spacings.small};
    padding: 0 ${({ theme }) => theme.spacings.large};
  }
`

const PreTitle = styled.div`
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${({ theme }) => theme.desktopSizes.h5};
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.1;
  text-align: center;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.desktopSizes.p};
    margin-bottom: ${({ theme }) => theme.spacings.small};
    letter-spacing: 1px;
  }
`

const Title = styled(H1)`
  margin: 0 auto !important;
  line-height: 1.1;
  text-align: center;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.white};
`

const SoloTitle = styled(H1)`
  margin: 0 auto !important;
  line-height: 1.1;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`

const PageHeader = ({ title, subTitle }) => {
  return (
    <>
      <PageHeadWrapper>
        {subTitle === '' && subTitle === null ? (
          <SoloTitle>{title}</SoloTitle>
        ) : (
          <>
            <PreTitle>{title}</PreTitle>
            <Title>{subTitle}</Title>
          </>
        )}
      </PageHeadWrapper>
    </>
  )
}

export default PageHeader
