import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import Seo from '../components/Seo'
import Header from '../components/Header/'
import PageHeader from '../components/Header/PageHeader'
import PortfolioFilter from '../components/Portfolio/PortfolioFilter'
import Footer from '../components/Footer'
import PortfolioGrid from '../components/Portfolio/PortfolioGrid'
import Loading from '../components/Portfolio/Loading'

const Page = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const PortWrapper = styled.div`
  width: 100%;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  margin-top: ${({ theme }) => theme.spacings.medium};
  margin-right: auto;
  margin-left: auto;
  padding: 0 ${({ theme }) => theme.spacings.medium};
`

const Portfolio = ({ data }) => {
  const catArray = data.allMdx.distinct
  const portItems = data.allMdx.edges
  const localName = data.site.siteMetadata.localStorageName

  const allCategories = ['all', ...catArray]
  const filteredData = button =>
    portItems.filter(item => item.node.frontmatter.category.includes(button))

  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(true)
  const [menuItem, setMenuItem] = useState([])

  const filter = button => {
    if (button === 'all') {
      setLocal(button)
      return setMenuItem(portItems)
    }
    const data = filteredData(button)

    setLocal(button)
    setMenuItem(data)
  }

  const setLocal = async item => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localName, item)
      setSelected(item)
    }
  }

  const getLocal = async () => {
    if (typeof window !== 'undefined') {
      const data = await localStorage.getItem(localName)
      if (data === null || data === 'all') {
        localStorage.setItem(localName, 'all')
        setSelected('all')
        setMenuItem(portItems)
      } else {
        setSelected(data)
        setMenuItem(filteredData(data))
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getLocal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <Page>
      <Seo title={'Portfolio'} />
      <Header />
      <PageHeader title={'Portfolio'} subTitle={"Projects we've completed"} />

      <PortWrapper>
        {loading ? (
          <Loading />
        ) : (
          <>
            <PortfolioFilter button={allCategories} filter={filter} selected={selected} />
            <PortfolioGrid data={menuItem} />
          </>
        )}
      </PortWrapper>
      <Footer />
    </Page>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        localStorageName
      }
    }
    allMdx(filter: { fileAbsolutePath: { regex: "/portfolio/" } }) {
      distinct(field: frontmatter___category)
      edges {
        node {
          frontmatter {
            title
            slug
            excerpt
            category
            featureImg {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default Portfolio

// const [selected, setSelected] = useState(
//   allCategories.includes(localStorage.getItem(localName)) === false
//     ? localStorage.setItem(localName, 'all')
//     : localStorage.getItem(localName),
// )
// const [loading, setLoading] = useState(true)
// const [menuItem, setMenuItem] = useState(selected === 'all' ? portItems : filteredData(selected))
