import React from 'react'
import styled from 'styled-components'

// Utils
import caseModifier from '../../utils/caseModifier'

const BtnWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  margin-bottom: ${({ theme }) => theme.spacings.xLarge};
`

const BtnItem = styled.button`
  border: none;
  color: inherit;
  cursor: pointer;
  background: none;
  text-align: center;
  margin: ${({ theme }) => theme.spacings.xSmall};
  line-height: 0;
  padding: ${({ theme }) => theme.spacings.small};
  font-size: ${props =>
    props.small === true
      ? ({ theme }) => theme.desktopSizes.small
      : ({ theme }) => theme.desktopSizes.p};

  font-weight: 700;
  outline: none;
  position: relative;
  transition: all 0.3s;
  border-radius: ${({ theme }) => theme.spacings.medium};
  border: 2px solid ${({ theme }) => theme.colors.pink};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.pink};
    color: ${({ theme }) => theme.colors.white};
  }

  /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    justify-content: center;
  } */
`

const BtnActive = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  background: ${({ theme }) => theme.colors.pink};
  text-align: center;
  margin: ${({ theme }) => theme.spacings.xSmall};
  line-height: 0;
  padding: ${({ theme }) => theme.spacings.small};
  font-size: ${props =>
    props.small === true
      ? ({ theme }) => theme.desktopSizes.small
      : ({ theme }) => theme.desktopSizes.p};

  font-weight: 700;
  outline: none;
  position: relative;
  transition: all 0.3s;
  border-radius: ${({ theme }) => theme.spacings.medium};
  border: 2px solid ${({ theme }) => theme.colors.pink};
  color: ${({ theme }) => theme.colors.white};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    justify-content: center;
    color: 'red';
  }
`

const PortfolioFilter = ({ button, filter, selected }) => {
  return (
    <BtnWrapper>
      {button.map((item, key) => {
        return (
          <React.Fragment key={key}>
            {selected === item ? (
              <BtnActive>{caseModifier(item.toString(), 'title')}</BtnActive>
            ) : (
              <BtnItem type="button" onClick={() => filter(item)}>
                {caseModifier(item.toString(), 'title')}
              </BtnItem>
            )}
          </React.Fragment>
        )
      })}
    </BtnWrapper>
  )
}

export default PortfolioFilter
