import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PortItemWrapper = styled.div`
  display: flex;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacings.small};
  background: ${({ theme }) => theme.colors.white};
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: grayscale(0%);
  }
`

export const PreviewImage = styled(GatsbyImage)`
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`

const PortfolioItem = ({ item }) => {
  const fm = item.node.frontmatter

  return (
    <>
      <PortItemWrapper>
        <ImageContainer>
          <Link to={`/${fm.slug}`}>
            <PreviewImage
              aspectratio={16 / 9}
              loading="eager"
              image={getImage(fm.featureImg)}
              alt={fm.title}
            />
          </Link>
        </ImageContainer>
      </PortItemWrapper>
    </>
  )
}

export default PortfolioItem
