const lower = str => str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())

const caseModifier = (name, type) => {
  let mod

  switch (type) {
    case 'up':
      mod = name.toString().toUpperCase()
      break
    case 'title':
      mod = lower(name)
      break
    default:
      mod = name.toString().toLowerCase()
  }
  return mod
}

export default caseModifier
