import React from 'react'
import styled from 'styled-components'

// Components
import PortfolioItem from './PortfolioItem'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-count: 3;
  justify-content: center;
  column-gap: ${({ theme }) => theme.spacings.xLarge};
  grid-row-gap: ${({ theme }) => theme.spacings.xLarge};
  margin-bottom: ${({ theme }) => theme.spacings.xxLarge};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    column-count: 2;
    margin-right: auto;
    margin-left: auto;
    column-gap: ${({ theme }) => theme.spacings.large};
    grid-row-gap: ${({ theme }) => theme.spacings.large};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    grid-template-columns: 1fr;
    column-count: 1;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: ${({ theme }) => theme.spacings.large};
    grid-row-gap: ${({ theme }) => theme.spacings.medium};
    max-width: ${({ theme }) => theme.breakpoints.md};
  }
`

const PortfolioGrid = ({ data }) => {
  return (
    <>
      <GridWrapper>
        {data.map((item, key) => {
          return (
            <React.Fragment key={key}>
              <PortfolioItem item={item} />
            </React.Fragment>
          )
        })}
      </GridWrapper>
    </>
  )
}

export default PortfolioGrid
